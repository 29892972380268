<!-- 学生状态 -->
<template>
    <div>
        <div class="content-main">
            <div class="filter-wrap">
                <expand-filter
                    :close-width="960"
                    :formData="formData"
                    marginBottom="0px"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @inputInput="handleFilterInput"
                    @changeCascader="handleFilterCascader"
                >
                </expand-filter>

                <el-tooltip class="item" effect="dark" content="设置初始化状态" placement="left">
                    <div class="setting"  @click="openStatusSetting">
                        <i class="el-icon-s-tools"></i>
                    </div>
                </el-tooltip>

            </div>

            <statistics-data
                :statistics-data="statisticsData"
                :style="{
                    marginTop: '10px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                }"
            ></statistics-data>

            <div
                class="content"
                :style="{
                    height: table_config.height + 'px',
                }"
            >
                <el-tree
                    :data="treeData"
                    node-key="id"
                    :key="treeKey"
                    ref="table"
                    :indent="0"
                    :default-expanded-keys="defaultExpandIds"
                    icon-class=" "
                    :props="{
                        children: 'child',
                        label: 'organName',
                    }"
                    @node-expand="handleNodeClick"
                >
                    <span
                        class="custom-tree-node"
                        :class="[data.tableData ? 'sepcial-node' : '']"
                        slot-scope="{node, data}"
                        v-loading="data.loading"
                    >
                        <div
                            v-if="!data.tableData"
                            class="my-tree-content"
                        >
                            <div

                                class="icon"
                                :class="{up: node.expanded}"
                            >
                                <i class="el-icon-arrow-right"></i>
                            </div>
                            <div class="title">{{ node.label }}</div>
                            <div class="info">
                                <div
                                    v-for="item in infoDist"
                                    :key="`${data.name}${item.keyName}`"
                                    class="info-item"
                                >
                                    {{ item.text }}{{ data[item.keyName] }}人
                                </div>
                            </div>
                        </div>

                        <template v-else>
                            <el-table
                                :data="data.tableData"
                                :header-cell-style="{ background: '#f9fafb' }"
                                v-loading="data.laoding"
                                :style="{
                                    width: '100%',
                                }"
                            >
                                <el-table-column
                                    prop="studentName"
                                    label="学生姓名"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="studentNo"
                                    label="学号"
                                    align="center"
                                >
                                    <template slot-scope="scope">
                                        {{scope.row.studentNo || "-"}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="studentCode"
                                    label="唯一号"
                                    align="center"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="status"
                                    label="出勤状态"
                                    align="center"
                                >
                                    <template slot-scope="scope">
                                        <el-link
                                            :type="getAttendancesStatusBycode(scope.row.status).type"
                                            :underline="false"
                                            @click="openAttendanceStatus(scope.row.studentId,scope.row.status,scope.row)"
                                        >
                                            {{getAttendancesStatusBycode(scope.row.status).name}}
                                        </el-link>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="undiscipline"
                                    label="标识"
                                    align="center"
                                    v-if="isHaveUpdateStudentDisciplinary"
                                >
                                    <template slot-scope="scope">
                                        <el-tag
                                            :type="getIdentifyingByCode(scope.row.undiscipline).type"
                                            :style="{
                                                cursor: scope.row.undiscipline == '1' ? 'pointer' : 'auto'
                                            }"
                                            effect="plain"
                                            @click="handleUndisciplineClick(scope.row)"
                                        >
                                            {{getIdentifyingByCode(scope.row.undiscipline).name}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="操作"
                                    align="center"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            type="text"
                                            @click="showHistoryAttendance(scope.row)"
                                        >历史出勤</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- <div class="btns" v-if="data.tableData && data.tableData.length > 0">
                                <el-button
                                    v-if="!data.isExpanded"
                                    type="text"
                                    @click="handleIsExpendChange(true,data)"
                                >更多<i class="el-icon-bottom el-icon--right"></i></el-button>
                                <el-button
                                    v-else
                                    type="text"
                                    @click="handleIsExpendChange(false,data)"
                                >收起<i class="el-icon-top el-icon--right"></i></el-button>
                            </div> -->
                        </template>


                    </span>
                </el-tree>
            </div>
        </div>

        <dialog-wrapper
            :dialogObj="statusSettingDialogObj"
            @handleClose="handleStatusSettingClose"
        >
            <el-form
                :model="statusFormData"
                label-width="0px"
                ref="formRef"
                label-posiiton="right"
            >
                <el-form-item label="" v-if="!statusFormData.id">
                    <div :style="{color:'red'}">您还未设置初始化状态，请设置</div>
                </el-form-item>
                <el-form-item label="" prop="id">
                    <el-radio-group v-model="statusFormData.id">
                        <el-radio
                            v-for="item in statusOptions"
                            :key="item.id"
                            :label="item.id"
                        >{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="">
                    <div :style="{color:'#909399'}">系统将在每天0:00初始化学生状态</div>
                </el-form-item>

                <div style="text-align: right;">
                    <el-button @click="handleStatusSettingClose">取消</el-button>
                    <el-button :loading="statusButtonLoading" @click="handleStatusSubmit" type="primary">确定</el-button>
                </div>
            </el-form>
        </dialog-wrapper>

        <dialog-wrapper
            :dialogObj="attendanceStatusDialogObj"
            @handleClose="handleAttendanceStatusClose"
        >
            <el-form
                :model="attendanceStatusFormData"
                label-width="0px"
                ref="formRef"
                label-posiiton="right"
            >
                <el-form-item label="" prop="id">
                    <el-radio-group v-model="attendanceStatusFormData.id">
                        <el-radio
                            v-for="item in attendanceStatusOptions"
                            :key="item.id"
                            :label="item.id"
                        >{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <div style="text-align: right;">
                    <el-button @click="handleAttendanceStatusClose">取消</el-button>
                    <el-button :loading="attendanceStatusButtonLoading" @click="handleAttendanceStatusSubmit" type="primary">确定</el-button>
                </div>
            </el-form>
        </dialog-wrapper>
    </div>
</template>

<script>

import {debounce,DialogWrapper} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import selectTree from "./Sub/selectTree";
import ExpandFilter from "./Sub/ExpandFilter";
import statisticsData from "@/components/scrollWrapper/SummaryStudentStatus/statisticsData.vue";


import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";
import {mapState} from "vuex";
import onResize from "@/mixins/onResize";
import RecordsList from '@/libs/pageListOptimal.js';

export default {
    name: "SummaryStudentStatusBaseScrollWrapper",
    mixins: [onResize],
    components: {
        selectTree,
        ExpandFilter,
        TableData,
        DialogWrapper,
        statisticsData,
    },
    data() {
        return {
            listQuery: {
                id: "",
                studentName: "",
                schoolId: this.$store.state.schoolId,
                attendanceDate:""
            },
            table_config: {
                height: "",
            },
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "date",
                        placeholder: "日期",
                        key: "attendanceDate",
                        value: "",
                        picker: {
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        },
                        clearable:false,
                        valueFormat: "yyyy-MM-dd",

                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "姓名",
                        key: "studentName",
                    },
                    {
                        key:"organId",
                        label: "",
                        placeholder: "班级",
                        type: "cascader",
                        value: [],
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            // checkStrictly: true,
                            emitPath:false
                        },
                        filter(data) {
                            return data.organType != 1;
                        },
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "出勤状态",
                        key: "status",
                        list: [
                            {
                                label: "未到",
                                value: "3",
                            },
                            {
                                label: "到校",
                                value: "1",
                            },
                            {
                                label: "请假",
                                value: "2",
                            },
                        ],
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "学号",
                        key: "studentNo",
                    },
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "唯一号",
                        key: "studentCode",
                    },

                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["checkStudentStatus"]
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["checkStudentStatus"]
                    },
                    // {
                    //     type: "primary",
                    //     text: "添加",
                    //     fn: "submit",
                    //     auth: ["organ:add"],
                    // },
                ],
                btnFormType: true,
            },
            treeData: [],
            statisticsData: {
                bedAllNum: "10",
                roomAllNum: "40",
                laveBedNym: "20",
            },
            infoDist: [
                {
                    keyName: "totalNum",
                    text: "应到",
                },
                {
                    keyName: "actualNum",
                    text: "实到",
                },
                {
                    keyName: "attendedNum",
                    text: "未到",
                },
                {
                    keyName: "leaveNum",
                    text: "请假",
                },
            ],
            treeDataOption: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
            /**
             * 所有设置
             */
            statusSettingDialogObj: {
                title: "设置初始化状态",
                dialogVisible: false,
                width: "400px",
            },
            statusFormData:{
                id:""
            },
            statusOptions:[
                {
                    id:"1",
                    name:"到校"
                },
                {
                    id:"2",
                    name:"未到"
                },
                {
                    id:"3",
                    name:"前一天状态"
                },
            ],
            statusButtonLoading:false,
            /**
            * 出勤状态
            */
           attendanceStatusDialogObj: {
                title: "修改出勤状态",
                dialogVisible: false,
                width: "400px",
            },
            attendanceStatusFormData:{
                id:"",
                stuId:"",
                row:{}
            },
            attendanceStatusOptions:[
                {
                    id:"3",
                    name:"未到"
                },
                {
                    id:"1",
                    name:"到校"
                },
                {
                    id:"2",
                    name:"请假"
                },
            ],
            attendanceStatusButtonLoading:false,
            defaultExpandIds:[],
            treeKey:1,
            studentExpanded:false // 学生是否展开
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            schoolStageList: (state) => state.schoolStageList,
        }),
        // 判断是否是当天，返回布尔值
        isToday() {
            let today = this.$moment().format("YYYY-MM-DD");
            let attendanceDate = this.listQuery.attendanceDate;
            return today == attendanceDate
        },
        isHaveUpdateStudentDisciplinary(){
            return hasPermission(["updateStudentDisciplinary"])
        }

    },
    async created() {},
    mounted() {
        this.init();
    },

    activated() {},
    methods: {
        init(){
            let recordsList = new RecordsList()
            this.setDefaultDate();
            if(recordsList.get(this.$route.name)){
                let data = recordsList.get(this.$route.name);
                this.formData = data.formData;
                this.listQuery = data.listQuery
            }
            this.setUndiscipline();
            this.formData.data.forEach((item) => {
                if(item.key == "organId"){
                    this.listQuery[item.key] = item.value.toString();
                } else {
                    this.listQuery[item.key] = item.value;
                }

            });

            this.getOrg();
            this.getTreeData(true);
            this.getTotal();
            this.getStatusSettting();
        },
        setUndiscipline(){
            let isHavePer = hasPermission(["updateStudentDisciplinary"])
            let obj = {
                type: "select",
                label: "",
                value: "",
                placeholder: "标识",
                key: "undiscipline",
                list: [
                    {
                        label: "正常",
                        value: "2",
                    },
                    {
                        label: "违纪",
                        value: "1",
                    },
                ],
            };
            let isHaveFilter = this.formData.data.some((item) => {
                return item.key == "undiscipline";
            });
            if(isHavePer && !isHaveFilter){
                let formDatalength = this.formData.data.length;
                this.formData.data.splice(formDatalength-1,0,obj);
                // this.formData.data.push(obj)
            }
        },
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.studentExpanded = true
                    this.formData.data.forEach((item) => {
                        if(item.key == "organId"){
                            this.listQuery[item.key] = item.value.toString();
                        } else {
                            this.listQuery[item.key] = item.value;
                        }

                    });

                    // this.formData.data.forEach((item) => {
                    //   this.listQuery[item.key] = item.value;
                    // });
                    this.getTreeData();
                    this.getTotal();
                    this.saveFilterOtions();
                    break;
                case "reset": // 重置
                this.studentExpanded = true

                    this.formData.data.forEach((item) => {
                        if(item.key == "attendanceDate"){
                            this.setDefaultDate();
                            this.listQuery[item.key] = item.value;
                        }else{
                            item.value = "";
                            this.listQuery[item.key] = "";
                        }
                    });
                    this.getTreeData();
                    this.getTotal();
                    this.saveFilterOtions();
                    break;
            }
        },
        // 根据标识获取出勤状态
        getAttendancesStatusBycode(code) {
            let stausArr = [
                {
                    code: "3",
                    name: "未到",
                    type: "danger"
                },
                {
                    code: "1",
                    name: "到校",
                    type: "success"
                },
                {
                    code: "2",
                    name: "请假",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
        // 根据标识获取标识状态
        getIdentifyingByCode(code) {
            let stausArr = [
                {
                    code: "2",
                    name: "正常",
                    type: "success"
                },
                {
                    code: "1",
                    name: "违纪",
                    type: "warning"
                },
            ];
            let status = stausArr.find((item) => item.code === code);
            return status;
        },
        // 历史出勤
        showHistoryAttendance(row){
            this.saveFilterOtions();
            this.$router.push({
                // path: "/summary/archives/summaryStudentInfo",
                name: "SummaryStudentStatusInfo",
                query: {
                    studentId:row.studentId,
                    studentName:row.studentName
                },
            });
        },
        // 设置默认时间为今天，格式为yyyy-mm-dd
        setDefaultDate() {
            let defaultTime = this.$moment().format("YYYY-MM-DD");
            this.formData.data.forEach((item) => {
                if(item.key === "attendanceDate") {
                    item.value = defaultTime;
                }
            })
        },

        // 获取年级班级下拉
        getOrg() {
            let obj = {...this.listQuery};
            Object.assign(obj, {jobScope: ["1", "2", "3"]});
            console.log(obj, "[[[[objobj]]]]1111111111111111111111111",this.listQuery);
            this._fet(
                // "/school/schoolOrgan/listByCondition",
                "/school/schoolOrgan/listAuthByCondition",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.organList = res.data.data.list;
                    const treeData = listToTree(res.data.data.list, {
                        parentKey: "parentOrg",
                    });
                    this.treeDataOption.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    console.log(
                        this.treeDataOption.dataList,
                        "this.treeDataOption.dataList",
                    );
                    let jsonList = JSON.stringify(this.treeDataOption.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["organId"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });

                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        getTreeData(first) {
            const _this = this;
            this._fet(
                    "/school/schoolOrganAttendance/list",
                    this.listQuery,
                ).then(async(res) => {
                    if (res.data.code === "200") {
                        this.treeData = res.data.data;

                        // 遍历树形结构，将最后一级的child插入一个对象
                        let result = [];

                        let lastChildNum = this.countLastLevelNodes(res.data.data,'child')

                        // 如果只有一个班级
                        if(lastChildNum == 1 && first){
                            let lastNode = this.getLastLevelNodes(res.data.data,'child')[0]
                            console.log(lastNode,"lastNode");
                            let tableData = await this.getStudentList(lastNode.organId)
                            let child = [
                                {
                                    id:`${lastNode.id}-child`,
                                    name: `${lastNode.organName}学生列表`,
                                    tableData: tableData,
                                    loading:false,
                                    isExpanded:true,//是否展开
                                }
                            ]
                            // result = [];
                            this.traverseTree(this.treeData,result,child[0])
                            this.treeKey = this.treeKey + 1;

                            // this.defaultExpandIds = [lastNode.id,`${lastNode.id}-child`]

                            // this.traverseTree(this.treeData,result,{name:null})
                            this.$nextTick(() => {

                                // 展开所有节点
                                this.eltreeExpandAll()
                            })


                        } else {
                            // 姓名、学号、唯一号
                            let keyArr = ['studentName','studentNo','studentCode'];
                            let classKey = "organId"
                            // 是否查人
                            let findUser = false;
                            for (let i = 0; i < keyArr.length; i++) {
                                if(this.listQuery[keyArr[i]]){
                                    findUser = true;
                                    break;
                                }

                            }
                            // 如果查人
                            if(findUser){
                                this.traverseTreeFun(this.treeData,'child',(node) => {
                                    // 如果是班级
                                    if(node.organType == "4"){
                                        console.log('按学生查，筛选的班级！！',node);
                                        let child = [
                                            {
                                                id:`${node.id}-child`,
                                                name: `${node.organName}学生列表`,
                                                tableData: node.childStu,
                                                loading:false,
                                                isExpanded:true,//是否展开
                                            }
                                        ]
                                        this.$set(node,'child',child)
                                        this.$nextTick(() => {

                                            // 展开所有节点
                                            this.eltreeExpandAll()
                                        })
                                    }
                                })
                            } else {
                                this.traverseTree(this.treeData,result,{name:null})
                            }

                            console.log(result);
                        }
                        this.loadingTable = false;

                    } else {
                        this.$message.error("数据请求失败，请重试");
                        this.loadingTable = false;

                    }
                });
        },
        async getStudentList(classId) {
            let param = this._.cloneDeep(this.listQuery);
            param.classId = classId
            let res =  await this._fet("/school/schoolStudentAttendance/list",param)
            if(res.data.code === "200"){
                return res.data.data
            } else {
                return []
            }

        },
        getTotal(){
            let param = {
                schoolId:this.schoolId,
                attendanceDate:this.listQuery.attendanceDate
            }
            this._fet("/school/schoolOrganAttendance/getTotalByAuth",param).then((res) => {
                if (res.data.code === "200") {
                    this.statisticsData = res.data.data;
                } else {}

            }).catch((err) => {

            });
        },
        async handleNodeClick(data,node) {
            const _this = this;
            console.log(data);
            // console.log(node);
            // 如果是班级
            if(data.organType && data.organType === "4" ){
                // 如果已有孩子数据
                if(data.child && data.child[0] && data.child[0].id) {
                    // this.$set(node,'expanded',!node.expanded)
                } else {
                    this.$set(data.child[0],'loading',true);
                    let tableData = await this.getStudentList(data.organId)
                    let child = [
                        {
                            id:`${data.id}-child`,
                            name: `${data.organName}学生列表`,
                            tableData: tableData,
                            isExpanded:_this.studentExpanded,//是否展开
                        }
                    ]
                    this.$set(data,'child',child)
                    this.$set(data.child[0],'loading',false);

                }

            }
        },
        traverseTree(data,parent,customObj) {
            if (!data.length) {
                return;
            }

            data.forEach(item => {
                if (item.child && item.child.length > 0) {
                    this.traverseTree(item.child, item, customObj);
                } else {
                    item.child = item.child || [];
                    item.child.push(customObj);
                }
            });
        },
        // 获取初始化状态设置
        getStatusSettting(){
            let param = {
                schoolId: this.schoolId,
                menuId: "030410",
                configType:"15"
            }
            this._fet("/school/schoolUserPreferenceDynamicConfig/getSchoolConfiguration",param).then((res) => {
                if (res.data.code === "200") {
                    if(res.data.data.schoolId){
                        this.statusFormData.id = res.data.data.config.status
                    }

                    // 如果未设置，则弹框
                    if(!this.statusFormData.id){
                        this.openStatusSetting();
                    }

                } else {


                }

            }).catch((err) => {
            })
        },
        openStatusSetting(){
            this.statusSettingDialogObj.dialogVisible = true
        },
        handleStatusSettingClose() {
            this.statusSettingDialogObj.dialogVisible = false
        },
        handleStatusSubmit(){
            if(!this.statusFormData.id){
                this.$message.warning("未设置,请选择");
                return;
            }
            let param = {
                schoolId: this.schoolId,
                menuId: "030410",
                configType:"15",
                config:{
                    status:this.statusFormData.id
                }
            }
            this.statusButtonLoading = true
            this._fet("/school/schoolUserPreferenceDynamicConfig/saveSchoolConfiguration",param).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("设置成功");

                } else {
                    this.$message.error("设置失败");


                }
                this.statusButtonLoading = false
                this.handleStatusSettingClose()
            }).catch((err) => {
                this.statusButtonLoading = false
                this.handleStatusSettingClose();
            });

        },

        openAttendanceStatus(stuId,status,row){
            let isHavePermission = hasPermission(["updateStudentStatus"])
            if(!isHavePermission){
                return
            }
            if(!this.isToday){
                this.$message.error("只能设置今天的学生状态");
                return;
            }
            this.attendanceStatusFormData.id = status;
            this.attendanceStatusFormData.stuId = stuId;
            this.attendanceStatusFormData.row = row;

            this.attendanceStatusDialogObj.dialogVisible = true
        },
        handleAttendanceStatusClose() {
            this.attendanceStatusDialogObj.dialogVisible = false
        },
        handleAttendanceStatusSubmit(){
            let param = {
                schoolId: this.schoolId,
                studentId: this.attendanceStatusFormData.stuId,
                status:this.attendanceStatusFormData.id
            }
            this.attendanceStatusButtonLoading = true
            this._fet("/school/schoolStudentAttendance/updateStatus",param).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("设置成功");
                    this.$set(this.attendanceStatusFormData.row,"status",this.attendanceStatusFormData.id)

                } else {
                    this.$message.error(res.data.msg || "设置失败");
                }
                this.attendanceStatusButtonLoading = false
                this.handleAttendanceStatusClose()
            }).catch((err) => {
                this.attendanceStatusButtonLoading = false
                this.handleAttendanceStatusClose();
            });

        },
        handleUndisciplineClick(row) {

            if(row.undiscipline == "2"){
                // this.$message.warning("该学生已经是正常状态");
                return;
            }

            if(!this.isToday){
                this.$message.warning("只能设置今天的学生状态");
                return;
            }

            this.$confirm('修改后学生将不再记为违纪', '确认要修改为正常吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let param = {
                    id:row.studentId,
                    undiscipline:"2"
                }
                let res = await this._fet("/school/schoolStudent/updateStudentDiscipline",param);
                if(res.data.code == "200"){
                    this.$message({
                        type: 'success',
                        message: '成功!'
                    });
                    this.$set(row,"undiscipline","2")
                }

                }).catch(() => {
            // this.$message({
            //         type: 'info',
            //         message: '已取消'
            //     });
            });
        },
        hanldeTableDataByIsExpend(tableData,isExpanded){
            console.log("tableData",tableData);
            console.log("isExpanded",isExpanded);
            if(tableData.length <= 0) {
                return []
            }

            if(isExpanded) {
                return tableData
            }

            let abnormalData = tableData.filter(item => {
                return ["2","3"].includes(item.status)
            })
            console.log("abnormalData",abnormalData);

            let normalData = tableData.filter(item => {
                return ["1"].includes(item.status)
            })
            console.log("normalData",normalData);

            // 将正常数据取前两个
            let normalDataSlice = normalData.slice(0,2)
            console.log("normalDataSlice",normalDataSlice);

            // 合并正常数据和异常数据
            let result = abnormalData.concat(normalDataSlice)
            console.log("result",result);

            return result

        },
        handleIsExpendChange(expand,data){
            this.$set(data,'isExpanded',expand)
        },
        // 获取树形数据最底层个数
        countLastLevelNodes(treeData,childrenKey = 'children') {
            if (!Array.isArray(treeData) || treeData.length === 0) {
            return 0;
            }
            console.log("treeData",treeData);

            let count = 0;

            function traverse(node) {
                if (!node[childrenKey] || node[childrenKey].length === 0) {
                    count++;
                    return;
                }

                for (let i = 0; i < node[childrenKey].length; i++) {
                    traverse(node[childrenKey][i]);
                }
            }

            for (let i = 0; i < treeData.length; i++) {
                traverse(treeData[i]);
            }

            return count;
        },
        getLastLevelNodes(treeData, childrenKey = 'children') {
            let lastLevelNodes = [];

            function traverse1(node) {
                if (!node[childrenKey] || node[childrenKey].length === 0) {
                    lastLevelNodes.push(node);
                    return;
                }

                for (let i = 0; i < node[childrenKey].length; i++) {
                    traverse1(node[childrenKey][i]);
                }
            }

            treeData.forEach(node => traverse1(node));

            return lastLevelNodes;
        },
        // 遍历数结构，并可以执行自定义函数
        traverseTreeFun(treeData, childrenKey = 'children', callback) {
            if (!Array.isArray(treeData) || treeData.length === 0) {
            return;
            }

            function traverse(node) {
                callback(node);

                if (!node[childrenKey] || node[childrenKey].length === 0) {
                return;
                }

                for (let i = 0; i < node[childrenKey].length; i++) {
                    traverse(node[childrenKey][i]);
                }
            }

            for (let i = 0; i < treeData.length; i++) {
                traverse(treeData[i]);
            }
        },
        // 保存筛选项
        saveFilterOtions() {
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                recordsList.remove(this.$route.name)
            }
            recordsList.set(this.$route.name,{
                formData: this.formData,
                listQuery: this.listQuery
            })
        },
        handleFilterInput:debounce(function(data){
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(keyArr.includes(data.key) && data.value) {
                    // 清空班级值
                    this.formData.data.forEach(formItem => {
                        if(formItem.key == classKey){
                            formItem.value = [];
                        }
                    })
                }
        },200),
        handleFilterCascader(data) {
            console.log(data);
            // 姓名、学号、唯一号与班级互斥
            let keyArr = ['studentName','studentNo','studentCode'];
            let classKey = "organId"
            if(data.data.key == classKey && data.data.value.length > 0){
                this.formData.data.forEach((item) => {
                    if(keyArr.includes(item.key)){
                        item.value = '';
                    }
                })
            }
        },
        eltreeExpandAll(){
            // 展开所有节点
            for (let i = 0; i < this.$refs.table.store._getAllNodes().length; i++) {
                // 遍历 el-tree 的每个节点，将节点的 expanded 属性设为 true 或 false
                this.$refs.table.store._getAllNodes()[i].expanded = true
            }
        }


    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    position: relative;

    .setting {
        position: absolute;
        right: 8px;
        top: 15px;
        cursor: pointer;
        color: #666;
        margin-right: 10px;
    }
}
.content {
    border-radius: 4px;
    margin-top: 2px;
    background-color: #fff;
    overflow-y: auto;
    padding-right: 10px;

    ::v-deep .el-tree {
        .el-tree-node__content {
            height: auto;
            margin-left: 10px;
        }
    }

    .custom-tree-node {
        flex: 1;



        ::v-deep .el-link.el-link--warning {
            color:#c600fb
        }

        .btns {
            border-bottom:1px solid #D7D9DB;
        }
    }

    .my-tree-content {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #ebeef5;
        width: 100%;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            color: #fff;
            background-color: #bfbfbf;
            font-size: 13px;
            transition: all 0.3s ease 0s;

            &:hover {
                background-color: #3c7fff;
            }

            &.up {
                transform: rotate(90deg);
                background-color: #3c7fff;
            }
        }

        .info {
            display: flex;
            flex: 1;
            margin-left: 10px;
            font-size: 14px;
            justify-content: space-around;
            .info-item {
                // margin-left: 100px;
            }
        }

        .title {
            font-weight: bold;
            margin-left: 10px;
            font-size: 14px;
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


    }

    .sepcial-node {
            cursor: auto;
        }
}

::v-deep .el-tree {
    .el-tree-node {
        position: relative;

        &::before {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            left: -1px;
            border-width: 1px;
            border-left: 1px dashed #c2c6cc;
        }

        &::after {
            content: "";
            width: 22px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 20px;
            border-top: 1px dashed #c2c6cc;
        }

        &:last-child::before {
            height: 21px;
        }

        &__content:hover {
            background-color: #fff;
        }

        &__children {
            padding-left: 30px;
        }
    }

    & > .el-tree-node {
        &::after {
            border-top: none;
        }

        &::before {
            border-left: none;
        }
    }

    .el-table {
        margin-left: -23px;
        border-left: 1px dashed #c2c6cc;

        .el-table__header .el-table__cell {
            position: relative;

            &:first-child::before {
                content: "";
                width: 22px;
                height: 1px;
                position: absolute;
                left: 0;
                top: 20px;
                border-top: 1px dashed #c2c6cc;
            }
        }

        .el-table__row .el-table__cell {
            position: relative;

            &:first-child::before {
                content: "";
                width: 22px;
                height: 1px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border-top: 1px dashed #c2c6cc;
            }
        }
    }
}
</style>
